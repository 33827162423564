import React from 'react';
import Chat from '../components/chat/Chat';
import Post from '../components/post/PostPage';
import Filter from '../components/filter/Filter';
import MyPosts from '../components/myPosts/MyPosts';
import NoMatch from '../components/noMatch/NoMatch';
import MyProfile from '../components/myProfile/MyProfile';
import Dashboard from '../components/dashboard/Dashboard';
import MyFavoritePosts from '../components/myFavoritePosts/MyFavoritePosts';
import IndexRoute from '../components/creation/IndexRoute';
import About from '../components/static_pages/about/About';
import TermsAndConditions from '../components/static_pages/terms/TermsAndConditions';
import PrivacyPolicy from '../components/static_pages/privacy_policy/PrivacyPolicy';
import ContactUs from '../components/static_pages/contact_us/ContactUs';
import UserProfile from '../components/userProfile/UserProfile';
import MyPurchases from '../components/myPurchases/MyPurchases';

interface IRoutes {
  path: string;
  component: React.FC<any>;
}

const localStorageKeys = {
  locale: 'gamar-locale',
  currency: 'gamar-currency',
  token: 'auth-gamar-ge-token',
  search: 'gamar-search',
  promo: 'gamar-promo',
  chat_storage_key: 'chatData',
  my_posts_view: 'gamar-my-posts-view',
};

enum REDIRECT_URLS {
  DASHBOARD = '/',
  ALL_CATEGORIES = '/category/all',
  CREATION_PAGE = '/creation',
  PRODUCT_PAGE = `/item/`,
  USER_PROFILE_PAGE = '/user/',
}

const Paths = {
  ChatPage: '/chat',
  ProductPage: '/item/:itemId',
  Filter: '/category/:category_id',
  MyPosts: '/my-posts',
  NoMatch: '*',
  MyProfile: '/my-profile',
  UserProfilePage: '/user/:userId',
  Dashboard: '/dashboard',
  DashboardOpenAuth: '/dashboard/open_auth',
  EditingPage: '/editing/:itemId',
  CreationPage: '/creation',
  MyFavoritePosts: '/my-favorite-posts',
  FilterWithParams: '/category/:category_id/:query',
  AboutPage: '/about-us',
  TermsAndConditionsPage: '/terms-and-conditions',
  PrivacyPolicyPage: '/site-policy',
  ContactPage: '/support',
  Register: '/register',
  MyPurchases: '/my-purchases',
};

const routes: IRoutes[] = [
  { path: Paths.Dashboard, component: Dashboard },
  { path: Paths.DashboardOpenAuth, component: Dashboard },
  { path: Paths.Filter, component: Filter },
  { path: Paths.FilterWithParams, component: Filter },
  { path: Paths.MyFavoritePosts, component: MyFavoritePosts },
  { path: Paths.MyPosts, component: MyPosts },
  { path: Paths.MyProfile, component: MyProfile },
  { path: Paths.MyPurchases, component: MyPurchases },
  { path: Paths.UserProfilePage, component: UserProfile },
  { path: Paths.ProductPage, component: Post },
  { path: Paths.CreationPage, component: IndexRoute },
  { path: Paths.EditingPage, component: IndexRoute },
  { path: Paths.ChatPage, component: Chat },
  { path: Paths.AboutPage, component: About },
  { path: Paths.TermsAndConditionsPage, component: TermsAndConditions },
  { path: Paths.PrivacyPolicyPage, component: PrivacyPolicy },
  { path: Paths.ContactPage, component: ContactUs },
  { path: Paths.Register, component: Dashboard },
  { path: Paths.NoMatch, component: NoMatch },
];

export { REDIRECT_URLS, Paths, routes, localStorageKeys };
