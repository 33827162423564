import { Dispatch, SetStateAction, useEffect } from 'react';

export const useScrollPosition = (setScrollPosition: Dispatch<SetStateAction<number>>) => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = (): void => {
    const position = window.scrollY;
    setScrollPosition(position);
  };
};
