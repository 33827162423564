import React, { FC, useEffect, useState } from 'react';
import './postActionsPage.scss';
import Header from '../header/Header';
import { CircleSteps, CreationComplete, FirstStep, SecondStep, ThirdStep } from '.';
import Footer from '../footer/Footer';
import { ICategory, IPost } from '../../types';
import { Snackbar } from '../default_components';
import { useMethodsSnackbar } from '../../hooks/useMethodsSnackbar';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

export enum STATE_PRICE {
  FREE = 'FREE',
  DISCUSSED = 'DISCUSSED',
}

const fieldsMap = new Map<string, string>();

interface ICreationPage {
  post?: IPost;
  postCategory?: ICategory;
  postSubCategory?: ICategory;
  sendPost: ({ body, id }: { body: FormData; id?: string }) => any;
  data: any;
  isLoading: boolean;
  isError: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

const PostActionsPage: FC<ICreationPage> = ({
  post,
  postCategory,
  postSubCategory,
  sendPost,
  isError,
  error,
  isLoading,
  data,
}) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [completeCreation, setCompleteCreation] = useState<boolean>(false);

  const [title, setTitle] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<ICategory | undefined>();
  const [selectedSubCategory, setSelectedSubCategory] = useState<ICategory | undefined>();
  const [description, setDescription] = useState<string>('');
  const [used, setUsed] = useState<boolean>(true);
  const [currency, setCurrency] = useState<string>('USD');
  const [price, setPrice] = useState<string>('');
  const [customPrice, setCustomPrice] = useState<string>('');
  const [showMobileNumber, setShowMobileNumber] = useState<boolean>(true);
  const [showLocation, setShowLocation] = useState<boolean>(true);
  const [images, setImages] = useState<Blob[]>([]);
  const [isHandmade, setIsHandmade] = useState<boolean>(false);

  const fields = fieldsMap;

  const { open, message, severity, closeSnackbar, getActiveSnackbar } = useMethodsSnackbar();

  useEffect(() => {
    if (data) {
      showCompleteMessage();
    } else if (error && 'status' in error) {
      getActiveSnackbar(error.data + ' ' + error.status, 'error');
    }
  }, [data, isError]);

  useEffect(() => {
    if (post && postCategory == (selectedCategory || selectedSubCategory)) {
      post.fields.forEach(field => {
        fields.set(field.id, field.value);
      });
    } else {
      fields.clear();
      (selectedSubCategory || selectedCategory)?.fields.map(field => fields.set(field.id, ''));
    }
  }, [selectedCategory, selectedSubCategory]);

  useEffect(() => {
    if (post && postCategory) {
      setTitle(post.title);
      setSelectedCategory(postCategory);
      setSelectedSubCategory(postSubCategory);
      setDescription(post.description);
      if (post.price != null) {
        if (post.price == 0.0) {
          setCustomPrice(STATE_PRICE.FREE);
        } else {
          setPrice(post.price.toString());
        }
      } else {
        setCustomPrice(STATE_PRICE.DISCUSSED);
      }
      setCurrency(post.currency.toUpperCase());
      setUsed(post.used);
      setShowLocation(!post.hide_locations);
      setShowMobileNumber(!post.hide_number);
      setIsHandmade(post.handmade);
      setPostImages(post);
    }
  }, [post, postCategory]);

  async function setPostImages(post: IPost) {
    let imagePromises = post.images.map(image =>
      fetch(image).then(response => response.blob())
    );

    let blobs = await Promise.all(imagePromises);

    setImages(blobs);
  }

  const showCompleteMessage = (): void => {
    setActiveStep(0);
    setCompleteCreation(true);
  };

  const validationOfDescription = (): boolean => {
    let len = description.split(' ').join('').split('\n').join('').length;
    return len > 10 && len < 4000;
  };

  const validationOfPrice = (): boolean =>
    customPrice.length > 3 ||
    (Number(price) >= 0 && Number(price) <= 100000 && price.length <= 9 && price.length >= 1);

  const moveToStep = (step: number): void => {
    if (activeStep > step) {
      setActiveStep(step);
    } else {
      previousStepWasCompleted(step) && setActiveStep(step);
    }
  };

  const previousStepWasCompleted = (step: number) => {
    switch (step) {
      case 1:
        return true;
      case 2:
        return title.length >= 4 && selectedCategory?.id;
      case 3:
        return validationOfPrice() && validationOfDescription();
      default:
        return false;
    }
  };

  const handleSendPost = (): void => {
    const formData = new FormData();

    if (!post && !images.length) return;
    formData.append('title', `${title}`);
    formData.append('description', `${description}`);
    if (customPrice !== STATE_PRICE.DISCUSSED) {
      formData.append('price', `${customPrice === STATE_PRICE.FREE ? 0 : Number(price)}`);
    }
    formData.append('hide_location', `${!showLocation}`);
    formData.append('hide_number', `${!showMobileNumber}`);
    formData.append('used', `${used}`);
    formData.append('category_id', `${(selectedSubCategory || selectedCategory)?.id}`);
    formData.append('currency', `${currency}`);
    fields.forEach((value, key) => {
      value && formData.append('fields[]', JSON.stringify({ id: key, value: value }));
    });
    images.forEach((file: Blob) => {
      formData.append('images[]', file, 'images.jpg');
    });
    formData.append('handmade', `${isHandmade}`);

    if (post) {
      sendPost({ body: formData, id: post.id });
    } else {
      sendPost({ body: formData });
    }
  };

  return (
    <React.Fragment>
      <Header />

      <div className="post-page-actions__container">
        <div className="post-actions-page">
          <CircleSteps
            activeStep={activeStep}
            creationComplete={completeCreation}
            moveToStep={moveToStep}
          />

          {activeStep === 1 && (
            <FirstStep
              title={title}
              selectedCategory={selectedCategory}
              selectedSubCategory={selectedSubCategory}
              setTitle={setTitle}
              setSelectedCategory={setSelectedCategory}
              setSelectedSubCategory={setSelectedSubCategory}
              moveToStep={moveToStep}
            />
          )}

          {activeStep === 2 && (
            <SecondStep
              post={post}
              description={description}
              price={price}
              customPrice={customPrice}
              images={images}
              setImages={setImages}
              setCustomPrice={setCustomPrice}
              setDescription={setDescription}
              setPrice={setPrice}
              setUsed={setUsed}
              moveToStep={moveToStep}
              validationOfPrice={validationOfPrice}
              validationOfDescription={validationOfDescription}
              selectedCategory={selectedSubCategory || selectedCategory}
              fields={fields}
              currency={currency}
              setCurrency={setCurrency}
              isHandmade={isHandmade}
              setIsHandmade={setIsHandmade}
            />
          )}

          {activeStep === 3 && (
            <ThirdStep
              sendPost={handleSendPost}
              isLoading={isLoading}
              showMobileNumber={showMobileNumber}
              showLocation={showLocation}
              setShowMobileNumber={setShowMobileNumber}
              setShowLocation={setShowLocation}
              moveToStep={moveToStep}
            />
          )}

          {completeCreation && <CreationComplete />}
        </div>
      </div>

      <Snackbar open={open} message={message} severity={severity} close={closeSnackbar} />
      <Footer />
    </React.Fragment>
  );
};

export default PostActionsPage;
